<template>
    <div id="app">
        <router-view></router-view>
        <b-navbar class="bottom-nav is-fixed-bottom nav-footer" style="padding-left: 20%;">
            <b-navbar-nav class="nav-menu">
                <b-nav-item style="padding: 0px">
                    <router-link to="/home" :class="['text-center', 'nav-link', {active: url === 'dashboard'}]">
                        <b-icon icon="house-door" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">မူလစာမျက်နှာ</span>
                    </router-link>
                </b-nav-item>
                <b-nav-item style="padding: 0px;">
                    <router-link to="/order-list" :class="['text-center', 'nav-link', {active: url === 'order-list'}]">
                        <b-icon icon="card-list" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">အော်ဒါများ</span>
                    </router-link>
                </b-nav-item>
                <!-- <b-nav-item style="padding: 0px;">
                    <router-link to="/return-list" :class="['text-center', 'nav-link', {active: url === 'return-list'}]">
                       <b-icon icon="card-list" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">ဗူးလက်ကျန်</span>
                    </router-link>
                </b-nav-item> -->
                <b-nav-item style="padding: 0px;">
                    <router-link to="/profile" :class="['text-center', 'nav-link', {active: url === 'profile'}]">
                        <b-icon icon="person" font-scale="1" class="nav-icon"></b-icon>
                        <span class="nav-text">အကောင့်</span>
                    </router-link>
                </b-nav-item>
            </b-navbar-nav>
        </b-navbar>
    </div>
</template>
<script>
    import {
        mapState
    } from 'vuex'
    import Sidebar from '../components/partials/Sidebar'
    export default {
        name: 'admin',
        metaInfo: {
            title: "Delivery",
            titleTemplate: "%s ← Htawara Water",
        },
        components: {
            Sidebar,
        },
        data() {
            return {
                url: "",
                count: 0
            }
        },
        watch: {
            '$route'(to, from) {
                this.url = to.name
            },
            '$store.state.cartCount': function () {
                this.count = this.$store.state.cartCount
            }
        },
        computed: {},
        methods: {
            logout() {
                window.localStorage.setItem('token', null)
                this.$router.replace('/login')
            }
        },
        async mounted() {
            const { getters } = this.$store;
            this.count = getters.getCart
            this.url = this.$route.name
        }
    }
</script>
<style lang="scss">
    @import "https://cdnjs.cloudflare.com/ajax/libs/bulma/0.7.2/css/bulma.min.css";

    // @import "../assets/main.css";
    // .nav-item {
    //     width: 50px;
    //     padding: 0px 54px 0px 20px;
    // }
    .cart {
        z-index: 999;
        position: absolute;
        background: red;
        width: 20px;
        height: 20px;
        color: #fff;
        bottom: 60%;
        right: 32%;
        font-size: 13px;
        font-weight: bolder;
        border-radius: 50%;
    }

    .navbar {
        z-index: 999 !important;
    }

    .nav-link {
        width: 100% !important;
        padding: 0px !important;
    }

    .nav-icon {
        width: 100% !important;
    }

    .nav-text {
        font-size: 15px !important;
    }
</style>